<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.News')" />
    <div class="tw_container">
      <tw-breadcrumbs v-if="breadcrumbs.length" :pages="breadcrumbs" />

      <div v-if="!informationContents" class="news_detail">
        <div class="news_detail_item">
          <el-skeleton :rows="10" animated />
        </div>
      </div>

      <template v-if="informationContents">
        <div v-if="isTwAdmin" class="admin-content">
          <div>
            <span v-if="informationDetail.publishedFlg === INFORMATIONS_PUBLISHED_FLG.DRAFT" class="draft_label">Draft</span>
            <div class="news-status-label">
              <span class="importance_icon" :class="[`news_status_${informationDetail.publishedStatus}`]"></span>
              <span>{{getNewsStatus(informationDetail.publishedStatus)}}</span>
            </div>
          </div>
          <div>
            <tw-button type="secondary" size="small" icon="plus" @click="addCopy">Copy</tw-button>
            <tw-button type="secondary" size="small" icon="edit" @click="edit">Edit</tw-button>
          </div>
        </div>

        <div class="news_detail">
          <div class="news_detail_item">
            <div>
              <div v-if="isNew" class="new_icon">NEW</div>
              <div class="title">
                <el-tooltip  v-if="informationDetail.priorityFlg === PRIORITY_FLG.ON" placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{getNewsType(informationDetail.newsType)}}</div>
                  <span class="importance_icon" :class="[`news_type_${informationDetail.newsType}`]" />
                </el-tooltip>
                {{informationContents.informationTitle}}
              </div>
              <div class="date">{{informationDetail.informationDateFrom | dateFormat}}</div>
            </div>

            <div class="contents tiptap_news_style" v-html="sanitize" />

            <div class="attach_file" v-if="informationDetail.informationDocuments.length > 0">
              <p>Attach File</p>
              <template v-for="doc in informationDetail.informationDocuments">
                <div :key="doc.informationDocumentId" class="file">
                  <div class="inner">
                    <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                      <div slot="content">{{doc.informationDocumentName}}</div>
                      <span>
                        <a class="file_name" @click.prevent="download(doc)" tabindex="-1">{{doc.informationDocumentName}}</a>
                      </span>
                    </el-tooltip>
                    <span class="meta">{{doc.informationDocumentSize | fileSize}},&nbsp;{{doc.updateDate | dateTimeFormat}}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="needPrev" class="prev" @click="prev" />
          <div v-if="needNext" class="next" @click="next" />
        </div>
      </template>
    </div>

    <tw-drawer v-if="isTwAdmin" :close-confirm="true" @close="drawerCloseConfirm" mode="edit">
      <template slot>
        <tw-news-edit-inner v-if="$store.state.drawerShow" :isShow="$store.state.drawerShow" :informationId="informationId" :propInformationDetail="informationDetail" :editState="editState" @update-news="updateNews" />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { LANGUAGE_CODE, INFORMATIONS_NEW_FLG, NEWS_TYPE_VARIABLES, INFORMATIONS_PUBLISHED_STATUS_VARIABLES, INFORMATIONS_PUBLISHED_FLG, PRIORITY_FLG, INFORMATIONS_API_USE_CODE } from 'lib-tw-common';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwHeader from '@/components/organisms/TwHeader';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwButton from '@/components/atoms/TwButton';
import TwNewsEditInner from '@/components/templates/TwNewsEditInner';


export default {
  name: 'NewsDetail',
  inject: ['reload'],
  props: {
    informationId: String
  },
  components: {
    TwBreadcrumbs,
    TwHeader,
    TwDrawer,
    TwButton,
    TwNewsEditInner,
  },
  data() {
    return {
      editState: '',
      informationDetail: null,
      INFORMATIONS_PUBLISHED_FLG,
      PRIORITY_FLG,
    };
  },
  computed: {
    // TW管理者かどうか
    isTwAdmin() {
      return this.$store.getters.isTwAdmin;
    },
    breadcrumbs() {
      if (this.informationDetail) {
        return [
          {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
          {label: this.$t('BreadCrumbs.News'), to: '/news/'},
          {label: this.informationContents.informationTitle, to: ''},
        ];
      } else {
        return [
          {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
          {label: this.$t('BreadCrumbs.News'), to: '/news/'},
        ];
      }
    },
    isNew() {
      return this.informationDetail.newFlg === INFORMATIONS_NEW_FLG.ON;
    },
    sanitize() {
      return this.$sanitize(this.informationContents.informationText, {
        allowedAttributes: {
          '*': ['class', 'style'], // sanitize-htmlの設定styleを許可する
          a: [ 'href', 'name', 'target' ],
          img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ]
        },
      }).replace(/\n/g, '<br>');
    },
    needPrev() {
      return !!this.informationDetail.prevInformationId;
    },
    needNext() {
      return !!this.informationDetail.nextInformationId;
    },
    informationContents() {
      if (!this.informationDetail) {
        return null;
      }
      return _.find(this.informationDetail.informationContents, {languageCode: LANGUAGE_CODE[this.$store.state.locale]});
    },
  },
  created() {
    // ページバックでドロワー開いたままを抑制
    if (this.$store.state.drawerShow) this.$store.commit('SET_DRAWER', false);

    this.fetch();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.reload();
  },
  methods: {
    fetch() {
      // bff_in_2 お知らせ詳細取得BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/informations/detail/{informationId}',
          query: {
            apiUseCode: INFORMATIONS_API_USE_CODE.LIST
          },
          path: {
            informationId: this.informationId,
          },
        }
      };

      $api.request(params)
      .then(res => {
        this.informationDetail = res.informationDetail;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    addCopy() {
      this.editState = 'Copy';
      this.$store.commit('SET_DRAWER', true);
    },
    edit() {
      this.editState = 'Edit';
      this.$store.commit('SET_DRAWER', true);
    },
    drawerCloseConfirm() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        this.editState = '';
        this.$store.commit('SET_DRAWER', false);
      })
    },
    updateNews(params) {
      if(params.type === 'remove') {
        this.$router.replace({ name: 'NewsList' });
      } else if (this.editState === 'Edit') {
        this.fetch();
      } else if (this.editState === 'Copy') {
        this.$router.push({
          name: 'NewsDetail',
          params: { informationId: params.informationId },
        });
      }
    },
    getNewsStatus(value) {
      return INFORMATIONS_PUBLISHED_STATUS_VARIABLES.find((item)=> item.code === value).label || '';
    },
    getNewsType(value) {
      return NEWS_TYPE_VARIABLES.find((item)=> item.code === value).label || '';
    },
    download(doc) {
      const query = {
        base64Flg: 0,
        informationDocumentId: doc.informationDocumentId,
      };
      // bff_in_5 お知らせファイル取得BFF
      const apiCode = 'get_/v1/informations/file-download';

      $api
      .txtDownload(query, doc.informationDocumentName, 'tw-transaction-bff-api', apiCode)
      .then(() => {})
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    prev() {
      this.$router.push({
        name: 'NewsDetail',
        params: { informationId: this.informationDetail.prevInformationId },
      });
    },
    next() {
      this.$router.push({
        name: 'NewsDetail',
        params: { informationId: this.informationDetail.nextInformationId },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .admin-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 840px;
    margin: 0 auto 20px;
    > div {
      display: flex;
      align-items: center;
    }
  }
  .news-status-label {
    display: inline-flex;
    margin-left: 10px;
  }

  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }

  .prev {
    left: 27px;
    background: url(../assets/images/icons/chevron_left_gray400.svg) no-repeat center;
    background-size: 100% auto;
  }

  .next {
    right: 27px;
    background: url(../assets/images/icons/chevron_right_gray400.svg) no-repeat center;
    background-size: 100% auto;
  }
</style>
